// extracted by mini-css-extract-plugin
export const Layout = "Layout-module--Layout--1Mylw";
export const landingBox = "Layout-module--landingBox--2ijwm";
export const skillContainers = "Layout-module--skillContainers--qSxT0";
export const skillSet = "Layout-module--skillSet--9XkhT";
export const skillIcons = "Layout-module--skillIcons--1yFlo";
export const skillHeading = "Layout-module--skillHeading--1FzOx";
export const contactContainer = "Layout-module--contactContainer--asVGq";
export const contact = "Layout-module--contact--2z9Zi";
export const contactForm = "Layout-module--contactForm--3sR63";
export const lineBreak = "Layout-module--lineBreak--1YUB-";
export const btnPrimary = "Layout-module--btn-primary--9IR_m";
export const project1 = "Layout-module--project1--NlnKC";
export const project2 = "Layout-module--project2--feUTD";
export const project3 = "Layout-module--project3--2r1R_";
export const project4 = "Layout-module--project4--A2Av9";
export const projectCard = "Layout-module--projectCard--3-2vQ";
export const detailTxt = "Layout-module--detailTxt--b3wzQ";
export const projects = "Layout-module--projects--jchPP";
export const awesome = "Layout-module--awesome--2FBNk";